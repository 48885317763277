<template>
  <div>
    <div class="d-flex sensor-gateway-warning p-2 mb-2" v-if="isSensorAlreadyLinked && this.offlineState.includes(this.selectedGatewayStatus)">
      <font-awesome-icon class="mt-1 mr-1" icon="exclamation-circle" />
      <div><b>Warning</b>: the sensor's linked gateway seems to be offline. Updating or unlinking the sensor could fail.</div>
    </div>

    <div class="d-flex sensor-gateway-info p-2 mb-2" v-if="this.selectedGatewayStatus === 'SERVER DISCONNECTED'">
      <font-awesome-icon class="mt-1 mr-1" icon="info-circle" />
      <div><b>Info</b>: the server has disconnected. Any operations on the sensor could fail.</div>
    </div>

    <fieldset class="p-2 form-col mb-3">
      <legend class="px-2">Sensor Properties</legend>
      <b-form-group label-for="sensor-name-input" label="Sensor Name" >
        <b-form-input id="sensor-name-input" v-model="name" trim placeholder="Sensor Name" :disabled="sensorOperationInProgress" />
      </b-form-group>

      <b-form-group label-for="sensor-type-input" label="Sensor Type" >
        <multiselect id="sensor-type-input" v-model="type" :options="sensorTypes" placeholder="Sensor Type" selectLabel=""
          deselectLabel="Remove" track-by="uuid" label="name" closeOnSelect :disabled="sensorOperationInProgress || isEdit" />
      </b-form-group>

      <b-form-group label-for="collection-protocol-input" label="Collection Protocol" v-if="type">
        <multiselect id="collection-protocol-input" v-model="collectionProtocol" :options="['Modbus']" :allowEmpty="false"
          placeholder="Collection Protocol" selectLabel="" deselectLabel="" closeOnSelect disabled />
      </b-form-group>

      <b-form-group
        label-for="unit-input"
        v-if="type && collectionProtocol === 'Modbus'"
        invalid-feedback="Must be an integer greater than 0."
        :state="modbusUnitValid">
        <template slot="label">
          <div class="d-flex align-items-center">
            Unit ID
            <font-awesome-icon v-if="isSensorAlreadyLinked" icon="info-circle" class="info-icon ml-2"
              v-b-tooltip.right="'Sensor must be unlinked from gateway to change the Unit ID'" />
          </div>
        </template>
        <b-form-input v-model="unit" placeholder="Unit" type="number" number min="1" :disabled="isSensorAlreadyLinked" />
      </b-form-group>

      <b-form-group label-for="form-collapse" class="mb-2" label-class="mb-0">
        <template slot="label">
          <div class="d-flex align-items-center" v-b-toggle.form-collapse>
            Custom Attributes
            <font-awesome-icon icon="caret-right" :class="[collapseOpen ? 'caret-down' : 'caret-right', 'mx-2']" />
          </div>
        </template>
        <b-collapse id="form-collapse" ref="collapse" @show="collapseOpen = true" @hide="collapseOpen = false" class="pt-2">
          <key-value-editor
            v-model="specialAttributes"
            emptyText="This sensor has no custom attributes. Add a new attribute below!"
            :existingOptions="existingAttributes"
          />
        </b-collapse>
      </b-form-group>

      <div class="d-flex">
        <b-button variant="primary" size="sm" @click="modalOkFunction" :disabled="!sensorOperationValid">{{ modalOkText }}</b-button>
        <div class="ml-2" v-if="sensorOperationInProgress"><msi-spinner class="no-select" :size="24" /></div>
      </div>
    </fieldset>

    <fieldset class="p-2 form-col mb-3" v-if="isEdit">
      <legend class="px-2">Sensor Link</legend>
      <b-form-group
        label-for="sensor-gateway-input"
        label="Linked Gateway"
        :invalid-feedback="sensorLinkedGatewayMessage"
        :state="!(this.offlineState.includes(this.selectedGatewayStatus))">
        <multiselect id="sensor-gateway-input" v-model="gateway" :options="gateways" placeholder="Gateway" selectLabel="" deselectLabel=""
          track-by="uuid" label="name" closeOnSelect :disabled="sensorLinkInProgress || isSensorAlreadyLinked" />
      </b-form-group>

      <div class="d-flex">
        <b-button variant="primary" size="sm" @click="() => linkSensor()" :disabled="!linkSensorValid">
          {{ isSensorAlreadyLinked ? 'Unlink' : 'Link' }}
        </b-button>
        <div class="ml-2" v-if="sensorLinkInProgress"><msi-spinner class="no-select" :size="24" /></div>
      </div>
    </fieldset>

    <b-modal title="Force Link?" hide-footer v-model="linkFailed">
      <div>
        This will tell the gateway to try to create the link even if it can't communicate with the sensor.
      </div>
      <div class="d-flex justify-content-end pt-2">
        <b-button variant="primary" size="sm" class="mr-2" @click="handleForceLink">Force Link</b-button>
        <b-button variant="secondary" size="sm" @click="cancelForceLink">Cancel</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import isEqual from 'lodash/isEqual';
import { get } from 'vuex-pathify';
import { BFormGroup, BFormInput, BCollapse, BButton, BModal } from 'bootstrap-vue';

import MsiSpinner from '../MsiSpinner.vue';
import KeyValueEditor from '../KeyValueEditor.vue';

export default {
  name: 'EditSensor',
  components: {
    Multiselect,
    MsiSpinner,
    KeyValueEditor,
    BFormGroup,
    BFormInput,
    BCollapse,
    BButton,
    BModal
  },
  props: {
    sensorTypes: {
      type: Array
    },
    gateways: {
      type: Array
    },
    sensor: {
      type: Object
    },
    isEdit: {
      type: Boolean
    },
    modalId: {
      type: String
    },
    existingAttributes: {
      type: Object
    },
    selectedSite: {
      type: Object
    }
  },
  data() {
    let unit;
    if (this.sensor.collectionSpec && this.sensor.collectionSpec.modbus && this.sensor.collectionSpec.modbus.unit) {
      unit = this.sensor.collectionSpec.modbus.unit;
    }

    return {
      name: this.sensor.name,
      type: this.sensorTypes.find(type => type.uuid === this.sensor.sensorTypeUuid),
      collectionProtocol: 'Modbus',
      unit,
      gateway: this.gateways.find(gateway => gateway.uuid === this.sensor.gatewayUuid),
      sensorOperationInProgress: false,
      collapseOpen: false,
      specialAttributes: this.sensor.attributes || {},
      sensorLinkInProgress: false,
      offlineState: ['OFFLINE', 'PENDING'],
      linkFailed: false
    };
  },
  computed: {
    gatewayStatus: get('gateways/getGatewayStatus'),
    modalOkText() {
      return this.isEdit ? 'Update Sensor' : 'Create Sensor';
    },
    modalOkFunction() {
      return this.isEdit ? this.editSensor : this.createSensor;
    },
    sensorPropertiesChanged() {
      let unit;
      if (this.sensor.collectionSpec && this.sensor.collectionSpec.modbus && this.sensor.collectionSpec.modbus.unit != null) {
        unit = this.sensor.collectionSpec.modbus.unit;
      }

      if (this.sensor.name !== this.name) return true;
      if (this.sensor.sensorTypeUuid ? !this.type : this.type) return true;
      if (unit !== this.unit) return true;
      return !isEqual(this.specialAttributes, this.sensor.attributes || {});
    },
    modbusUnitValid() {
      return Number.isInteger(this.unit) && this.unit >= 1;
    },
    sensorPropertiesValid() {
      return this.name && this.type && this.collectionProtocol && this.modbusUnitValid;
    },
    sensorOperationValid() {
      return this.sensorPropertiesChanged && this.sensorPropertiesValid && !this.sensorOperationInProgress;
    },
    isSensorAlreadyLinked() {
      if (this.sensor.gatewayUuid) return true;
      return false;
    },
    selectedGatewayStatus() {
      if (!this.gateway) return null;
      return this.gatewayStatus(this.gateway.code);
    },
    sensorLinkedGatewayMessage() {
      if (this.offlineState.includes(this.selectedGatewayStatus) && this.isSensorAlreadyLinked) {
        return 'The gateway seems to be offline. Unlinking the sensor from it will likely fail.';
      }

      if (this.offlineState.includes(this.selectedGatewayStatus) && !this.isSensorAlreadyLinked) {
        return 'The selected gateway seems to be offline. Linking the sensor to it will likely fail.';
      }

      return null;
    },
    sensorLinkChanged() {
      if (!this.isSensorAlreadyLinked && this.gateway) return true;
      if (this.isSensorAlreadyLinked) return true;
      return false;
    },
    linkSensorValid() {
      return !this.sensorLinkInProgress && this.sensorLinkChanged && this.gateway;
    }
  },
  methods: {
    async createSensor() {
      if (!this.selectedSite || !this.sensorOperationValid) return;

      try {
        this.sensorOperationInProgress = true;
        const body = { name: this.name, sensorTypeUuid: this.type.uuid };
        if (this.specialAttributes) body.attributes = { ...this.specialAttributes };
        if (this.collectionProtocol === 'Modbus' && this.unit) body.collectionSpec = { modbus: { unit: this.unit } };
        const response = await this.$daqApi.post(`/sites/${this.selectedSite.id}/sensors`, { body });
        this.$toastSuccess('Successful', response.message);
        this.$gtagPlugin.events.Sensor.new();
        await this.$listeners.reload();
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.sensorOperationInProgress = false;
      }
    },
    async editSensor() {
      if (!this.selectedSite || !this.sensorOperationValid) return;

      try {
        this.sensorOperationInProgress = true;
        const body = { name: this.name };
        if (this.specialAttributes) body.attributes = { ...this.specialAttributes };
        if (this.collectionProtocol === 'Modbus' && this.unit) body.collectionSpec = { modbus: { unit: this.unit } };
        const response = await this.$daqApi.put(`/sites/${this.selectedSite.id}/sensors/${this.sensor.uuid}`, { body });
        this.$toastSuccess('Successful', response.message);
        this.$gtagPlugin.events.Sensor.edit({ event_label: this.sensor.uuid });
        await this.$listeners.reload();
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.sensorOperationInProgress = false;
      }
    },
    async linkSensor(force = false) {
      if (!this.selectedSite || !this.linkSensorValid) return;

      try {
        this.sensorLinkInProgress = true;
        if (!this.isSensorAlreadyLinked && this.gateway) {
          const response = await this.$daqApi.post(`/sites/${this.selectedSite.id}/gateways/${this.gateway.uuid}/link-sensor`, {
            body: { sensorUuid: this.sensor.uuid, force }
          });

          this.$toastSuccess('Successful', response.message);
          await this.$listeners.reload();
        } else if (this.isSensorAlreadyLinked && this.gateway) {
          const response = await this.$daqApi.post(`/sites/${this.selectedSite.id}/gateways/${this.gateway.uuid}/unlink-sensor`, {
            body: { sensorUuid: this.sensor.uuid }
          });

          this.$toastSuccess('Successful', response.message);
          await this.$listeners.reload();
        }
      } catch (e) {
        if (e.name === 'ApiError') {
          this.$toastError(`Error ${e.status || ''}`, e.message);
          if (e.status === 503 && !this.isSensorAlreadyLinked) this.linkFailed = true;
        } else {
          throw e;
        }
      } finally {
        this.sensorLinkInProgress = false;
      }
    },
    handleForceLink() {
      this.linkFailed = false;
      this.linkSensor(true);
    },
    cancelForceLink() {
      this.linkFailed = false;
    }
  },
  watch: {
    sensor() {
      if (!this.sensor) return;
      let unit;
      if (this.sensor.collectionSpec && this.sensor.collectionSpec.modbus && this.sensor.collectionSpec.modbus.unit != null) {
        unit = this.sensor.collectionSpec.modbus.unit;
      }

      this.name = this.sensor.name;
      this.type = this.sensorTypes.find(type => type.uuid === this.sensor.sensorTypeUuid);
      this.unit = unit;
      this.gateway = this.gateways.find(gateway => gateway.uuid === this.sensor.gatewayUuid);
    }
  }
};
</script>

<style lang="scss" scoped>
.caret-right {
  transform: rotate(0deg);
  transition: transform 0.3s linear;
}

.caret-down {
  transform: rotate(90deg);
  transition: transform 0.3s linear;
}

fieldset.form-col {
  border: thin solid rgb(233, 236, 239);
  border-radius: 5px;
}

fieldset.form-col legend {
  font-size: 0.95em;
  padding-right: 1px;
  padding-left: 1px;
  width: auto;
  margin: 0;
  color: rgb(195, 195, 195);
}

.sensor-gateway-warning {
  color: #c5970b;
  font-size: 0.8rem;
  background-color: #fdf7e4;
}

.sensor-gateway-info {
  color: rgb(105, 105, 105);
  font-size: 0.8rem;
  background-color: #f5f5f5;
}

.info-icon {
  cursor: pointer;
  color: #aaa;
}
.info-icon:hover {
  color: #888;
}
</style>
