<template>
  <div>
    <b-row align-h="between" class="mx-3">
      <h1 class="display-4 sensor-link-header">Sensors</h1>
    </b-row>

    <b-row class="my-3 m-0" no-gutters align-h="between" align-v="center">
      <b-col lg="6" class="d-flex my-1">
        <b-button size="sm" variant="primary" class="mr-2" aria-label="add new sensor" @click="handleCreateClick"
          :disabled="requestInProgress || loadingSites">
          Add New Sensor
        </b-button>
        <b-form-checkbox v-model="showArchived" button class="archived-button" size="sm">
        {{showArchived ? 'Hide' : 'Show'}} Archived
      </b-form-checkbox>
      </b-col>
      <b-col lg="6" class="d-flex my-1">
        <b-input-group size="sm">
          <b-form-input
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          ></b-form-input>
          <b-input-group-append>
            <b-button aria-label="clear" :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <div class="d-flex flex-column justify-content-between flex-grow-1">
      <b-table
        id="sensors-table"
        :items="sensors"
        :fields="tableFields"
        primaryKey="uuid"
        :show-empty="!requestInProgress && !loadingSites"
        :busy="requestInProgress || loadingSites"
        emptyText="No sensors available"
        selectMode="range"
        :per-page="perPage"
        :current-page="currentPage"
        hover
        striped
        sort-icon-left
        responsive
        stacked="sm"
        @filtered="onFiltered"
        :filter="filter"
        :filter-function="filterMethod"
      >
        <template v-slot:cell(name)="{ item }">
          <div
            v-if="item.type && item.typeVirtual"
            class="d-flex align-items-center">
            {{ item.name }}
            <b-badge variant="secondary" class="ml-2">Virtual</b-badge>
          </div>
          <div v-else>
            {{ item.name }}
          </div>
        </template>

        <template v-slot:cell(gateway)="data">
          <div class="d-flex" v-if="data.item.gateway">
            {{ data.item.gateway }}
            <div v-if="data.item.gatewayStatus === 'OFFLINE'" class="pl-2">
              <div v-b-tooltip.hover.right title="The sensor's linked gateway seems to be offline. Updating or unlinking the sensor could fail.">
                <font-awesome-icon icon="exclamation-circle" class="error-icon" />
              </div>
            </div>
            <div v-if="data.item.gatewayStatus === 'SERVER DISCONNECTED'" class="pl-2">
              <div v-b-tooltip.hover.right title="The server is disconnected">
                <font-awesome-icon icon="info-circle" style="color:grey;" />
              </div>
            </div>
          </div>
          <div v-else></div>
        </template>

        <template v-slot:cell(configure)="{ item, toggleDetails }">
          <div class="d-inline-flex justify-content-end w-100 pr-0 pr-sm-1 pr-md-2 pr-lg-3">
            <b-button
              size="sm"
              variant="primary"
              class="mr-2"
              aria-label="tools"
              @click="() => handleEditClick(item)"
              :disabled="requestInProgress || (item.type && item.typeVirtual)">
              <font-awesome-icon icon='wrench' />
            </b-button>
            <div
              tabindex="0"
              v-b-tooltip.hover.top
              :title="!Object.keys(item.attributes || {}).length ? 'There are no custom attributes for this sensor.' : ''">
              <b-button class="mr-2" aria-label="info" size="sm" variant="info" @click="toggleDetails"
              :disabled="!Object.keys(item.attributes || {}).length">
                <font-awesome-icon icon='info-circle' />
              </b-button>
            </div>
            <b-button
              size="sm"
              :variant="item.archived ? 'success' : 'danger'"
              aria-label="toggle"
              @click="() => handleArchive(item)"
              v-b-tooltip.hover
              :title="item.archived ? 'Restore' : 'Archive'">
              <font-awesome-icon icon="archive" />
            </b-button>
          </div>
        </template>
        <template v-slot:row-details="{ item }">
          <b-table
            :items="mapDataToRow(item)"
            :fields="['name', 'value']"
            striped
            outlined
            responsive
            stacked="sm"
            small
            hover
            fixed
            table-variant="light"
            head-variant="light"
          />
        </template>
      </b-table>
      <div class="w-100 d-flex justify-content-center">
        <msi-spinner class="no-select" :size="60" v-if="requestInProgress || loadingSites"/>
      </div>
      <div class="d-flex justify-content-center mt-2 w-100">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          :limit="$feature.mobile ? 5 : 8"
          aria-controls="sensors-table"
          class="m-0"
        ></b-pagination>
      </div>
    </div>

    <b-modal id="sensor-config-modal" :title="modalTitleText" hide-footer>
      <edit-sensor
        modalId="sensor-config-modal"
        :sensor="editingSensor"
        :sensorTypes="creatableSensorTypes"
        :gateways="gateways"
        :isEdit="isEdit"
        :existingAttributes="existingCustomAttributes"
        :selectedSite="selectedSite"
        @reload="reload"
        slot="default"/>
    </b-modal>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import { BRow, BCol, BButton, BFormCheckbox, BInputGroup, BInputGroupAppend, BTable, BPagination, BModal, BFormInput, BBadge } from 'bootstrap-vue';

import EditSensor from './EditSensor.vue';
import MsiSpinner from '../MsiSpinner.vue';
import store from '../../store';

export default {
  name: 'SensorList',
  components: {
    EditSensor,
    MsiSpinner,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BPagination,
    BModal,
    BFormInput,
    BBadge
  },
  data() {
    return {
      requestInProgress: false,
      editingSensorUuid: null,
      isEdit: false,
      tableFields: [
        { key: 'name', sortable: true },
        { key: 'type', sortable: true },
        { key: 'gateway', sortable: true },
        { key: 'configure', label: ' ' }
      ],
      perPage: 12,
      currentPage: 1,
      totalRows: 1,
      filter: '',
      showArchived: false
    };
  },
  computed: {
    selectedSite: get('sites/selectedSite'),
    getSiteSensors: get('sensors/getSiteSensors'),
    getSensorByUuid: get('sensors/getSensorByUuid'),
    getSiteGateways: get('gateways/getSiteGateways'),
    getSensorTypeByUuid: get('sensorTypes/getSensorTypeByUuid'),
    sensorTypes: get('sensorTypes/sensorTypes'),
    getGatewayStatus: get('gateways/getGatewayStatus'),
    loadingSites: get('sites/loadingSites'),
    gateways() {
      if (!this.selectedSite) return [];
      return this.getSiteGateways(this.selectedSite.id);
    },
    sensors() {
      if (!this.selectedSite) return [];

      const sensors = this.getSiteSensors(this.selectedSite.id, this.showArchived);
      return sensors.map((sensor) => {
        const type = this.getSensorTypeByUuid(sensor.sensorTypeUuid);
        const gateway = this.gateways.find(g => g.uuid === sensor.gatewayUuid);
        const copy = { ...sensor };
        if (type) {
          copy.type = type.name;
          copy.typeVirtual = type.virtual;
        }
        if (gateway) {
          copy.gateway = gateway.name;
          copy.gatewayStatus = this.getGatewayStatus(gateway.code);
        }
        return copy;
      });
    },
    modalTitleText() {
      if (this.isEdit) return 'Sensor Configuration';
      return 'New Sensor';
    },
    existingCustomAttributes() {
      const existingAttributes = {};
      this.sensors.forEach(({ attributes }) => {
        if (!attributes) return;
        Object.entries(attributes).forEach(([key, value]) => {
          if (!existingAttributes[key]) existingAttributes[key] = new Set();
          existingAttributes[key].add(value);
        });
      });
      return existingAttributes;
    },
    editingSensor() {
      if (this.editingSensorUuid) return this.sensors.find(sensor => sensor.uuid === this.editingSensorUuid);
      return { name: '', sensorTypeUuid: null, gatewayUuid: null };
    },
    creatableSensorTypes() {
      return this.sensorTypes.filter(sensorType => !sensorType.virtual);
    }
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    handleEditClick(sensor) {
      this.editingSensorUuid = sensor.uuid;
      this.isEdit = true;
      this.$bvModal.show('sensor-config-modal');
    },
    handleCreateClick() {
      this.editingSensorUuid = null;
      this.isEdit = false;
      this.$bvModal.show('sensor-config-modal');
    },
    async reload() {
      this.requestInProgress = true;
      await store.set('sensors/sensors');
      this.requestInProgress = false;
    },
    mapDataToRow(data) {
      return Object.entries(data.attributes).map(([name, value]) => ({ name, value }));
    },
    filterMethod(sensor, filterString) {
      const parts = filterString.split(/ +/g).map(s => s.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'));
      const regex = RegExp(parts.join('.*'), 'i');
      if (sensor.name.match(regex)) return true;
      if (sensor.type.match(regex)) return true;
      if (sensor.gateway && sensor.gateway.match(regex)) return true;
      return false;
    },
    async handleArchive(sensor) {
      if (!this.selectedSite || !sensor || !sensor.uuid) return;

      try {
        this.requestInProgress = true;
        const endpoint = sensor.archived ? 'unarchive' : 'archive';
        const response = await this.$daqApi.put(`/sites/${this.selectedSite.id}/sensors/${sensor.uuid}/${endpoint}`);
        this.$toastSuccess('Successful', response.message);
        this.$gtagPlugin.events.Sensor.archive({ event_label: sensor.uuid });
        this.reload();
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.requestInProgress = false;
      }
    }
  },
  watch: {
    sensors: {
      immediate: true,
      handler(sensors) {
        this.totalRows = sensors.length;
      }
    }
  }
};
</script>

<style scoped>
  .sensor-link-header {
    font-size: 1.8rem;
  }
  .link-in-progress {
    color: rgb(151, 154, 156);
  }
  .error-icon {
    color: red;
    cursor: pointer;
  }
</style>

<style lang="scss">
  .archived-button .btn.active {
    background-color: $msi-orange !important;
    color: white !important;
    border-color: $msi-orange !important;
  }
</style>
