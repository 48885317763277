<template>
<b-container fluid class="sensor-config-container m-0 px-2 px-md-3 pt-2 pt-md-3 d-flex flex-column">
  <div class="flex-grow-1 d-flex flex-column pb-2 pb-md-3 min-height-fit">
    <b-card no-body class="m-0 p-3 flex-grow-1 d-flex flex-column min-height-fit">
      <sensor-list />
    </b-card>
  </div>
</b-container>
</template>

<script>
import { BContainer, BCard } from 'bootstrap-vue';

import SensorList from '../components/SiteSettings/SensorList.vue';

export default {
  name: 'SensorConfig',
  components: {
    SensorList,
    BContainer,
    BCard
  }
};
</script>

<style scoped>
  .sensor-config-container {
    height: calc(100vh - 105px)
  }

  .min-height-fit {
    min-height: fit-content;
  }
</style>
