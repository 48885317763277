var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mx-3",attrs:{"align-h":"between"}},[_c('h1',{staticClass:"display-4 sensor-link-header"},[_vm._v("Sensors")])]),_c('b-row',{staticClass:"my-3 m-0",attrs:{"no-gutters":"","align-h":"between","align-v":"center"}},[_c('b-col',{staticClass:"d-flex my-1",attrs:{"lg":"6"}},[_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm","variant":"primary","aria-label":"add new sensor","disabled":_vm.requestInProgress || _vm.loadingSites},on:{"click":_vm.handleCreateClick}},[_vm._v(" Add New Sensor ")]),_c('b-form-checkbox',{staticClass:"archived-button",attrs:{"button":"","size":"sm"},model:{value:(_vm.showArchived),callback:function ($$v) {_vm.showArchived=$$v},expression:"showArchived"}},[_vm._v(" "+_vm._s(_vm.showArchived ? 'Hide' : 'Show')+" Archived ")])],1),_c('b-col',{staticClass:"d-flex my-1",attrs:{"lg":"6"}},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"type":"search","placeholder":"Type to Search"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"aria-label":"clear","disabled":!_vm.filter},on:{"click":function($event){_vm.filter = ''}}},[_vm._v("Clear")])],1)],1)],1)],1),_c('div',{staticClass:"d-flex flex-column justify-content-between flex-grow-1"},[_c('b-table',{attrs:{"id":"sensors-table","items":_vm.sensors,"fields":_vm.tableFields,"primaryKey":"uuid","show-empty":!_vm.requestInProgress && !_vm.loadingSites,"busy":_vm.requestInProgress || _vm.loadingSites,"emptyText":"No sensors available","selectMode":"range","per-page":_vm.perPage,"current-page":_vm.currentPage,"hover":"","striped":"","sort-icon-left":"","responsive":"","stacked":"sm","filter":_vm.filter,"filter-function":_vm.filterMethod},on:{"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [(item.type && item.typeVirtual)?_c('div',{staticClass:"d-flex align-items-center"},[_vm._v(" "+_vm._s(item.name)+" "),_c('b-badge',{staticClass:"ml-2",attrs:{"variant":"secondary"}},[_vm._v("Virtual")])],1):_c('div',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"cell(gateway)",fn:function(data){return [(data.item.gateway)?_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(data.item.gateway)+" "),(data.item.gatewayStatus === 'OFFLINE')?_c('div',{staticClass:"pl-2"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",modifiers:{"hover":true,"right":true}}],attrs:{"title":"The sensor's linked gateway seems to be offline. Updating or unlinking the sensor could fail."}},[_c('font-awesome-icon',{staticClass:"error-icon",attrs:{"icon":"exclamation-circle"}})],1)]):_vm._e(),(data.item.gatewayStatus === 'SERVER DISCONNECTED')?_c('div',{staticClass:"pl-2"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",modifiers:{"hover":true,"right":true}}],attrs:{"title":"The server is disconnected"}},[_c('font-awesome-icon',{staticStyle:{"color":"grey"},attrs:{"icon":"info-circle"}})],1)]):_vm._e()]):_c('div')]}},{key:"cell(configure)",fn:function(ref){
var item = ref.item;
var toggleDetails = ref.toggleDetails;
return [_c('div',{staticClass:"d-inline-flex justify-content-end w-100 pr-0 pr-sm-1 pr-md-2 pr-lg-3"},[_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm","variant":"primary","aria-label":"tools","disabled":_vm.requestInProgress || (item.type && item.typeVirtual)},on:{"click":function () { return _vm.handleEditClick(item); }}},[_c('font-awesome-icon',{attrs:{"icon":"wrench"}})],1),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",modifiers:{"hover":true,"top":true}}],attrs:{"tabindex":"0","title":!Object.keys(item.attributes || {}).length ? 'There are no custom attributes for this sensor.' : ''}},[_c('b-button',{staticClass:"mr-2",attrs:{"aria-label":"info","size":"sm","variant":"info","disabled":!Object.keys(item.attributes || {}).length},on:{"click":toggleDetails}},[_c('font-awesome-icon',{attrs:{"icon":"info-circle"}})],1)],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"size":"sm","variant":item.archived ? 'success' : 'danger',"aria-label":"toggle","title":item.archived ? 'Restore' : 'Archive'},on:{"click":function () { return _vm.handleArchive(item); }}},[_c('font-awesome-icon',{attrs:{"icon":"archive"}})],1)],1)]}},{key:"row-details",fn:function(ref){
var item = ref.item;
return [_c('b-table',{attrs:{"items":_vm.mapDataToRow(item),"fields":['name', 'value'],"striped":"","outlined":"","responsive":"","stacked":"sm","small":"","hover":"","fixed":"","table-variant":"light","head-variant":"light"}})]}}])}),_c('div',{staticClass:"w-100 d-flex justify-content-center"},[(_vm.requestInProgress || _vm.loadingSites)?_c('msi-spinner',{staticClass:"no-select",attrs:{"size":60}}):_vm._e()],1),_c('div',{staticClass:"d-flex justify-content-center mt-2 w-100"},[_c('b-pagination',{staticClass:"m-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"limit":_vm.$feature.mobile ? 5 : 8,"aria-controls":"sensors-table"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1),_c('b-modal',{attrs:{"id":"sensor-config-modal","title":_vm.modalTitleText,"hide-footer":""}},[_c('edit-sensor',{attrs:{"slot":"default","modalId":"sensor-config-modal","sensor":_vm.editingSensor,"sensorTypes":_vm.creatableSensorTypes,"gateways":_vm.gateways,"isEdit":_vm.isEdit,"existingAttributes":_vm.existingCustomAttributes,"selectedSite":_vm.selectedSite},on:{"reload":_vm.reload},slot:"default"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }